.catering .carousel-container {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 80px;
    max-height: 760px;
  }
  
  .catering .carousel-background {
    background-size: cover;
    background-position: center;
    padding: 50px 0;
    padding: 50px;
  }
  
  .catering .card {
    background: rgba(255, 255, 255, 0.329);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s;
    width: 280px !important;
    color: white;
    height: auto;
  }
  .catering .carousel-container .slick-slider .slick-next {
    left: 250px;
  }
  .catering .card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    width: 100%;
    max-width: 100%;
    display: block;
    border-radius: 5px
  }
  
  .catering .card h3 {
    margin: 10px 0;
  }
  
  .catering .card p {
    margin: 5px 0;
  }
  
  /* Add hover effect for cards */
  .catering .card:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .catering .card {
      padding: 15px; 
    }
  }
  
  @media (max-width: 992px) {
    .catering .carousel-background {
      padding: 30px 0;
    }
  }
  
  @media (max-width: 768px) {
    .catering .carousel-background {
      padding: 20px 0;
    }
  
    .catering .card {
      padding: 10px; 
    }
  
    .catering .card h3 {
      font-size: 1.2em; 
    }
  
    .catering .card p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 576px) {
    .catering .carousel-background {
      padding: 10px 0; 
    }
  
    .catering .card {
      padding: 5px;
    }
  
    .catering .card h3 {
      font-size: 1em;
    }
  
    .catering .card p {
      font-size: 0.8em;
    }
  }
  
  .catering .slick-slider{
    width: 100%;
    height: 540px;
  }
  .catering .slick-center .card {
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    transition: background-color 2s;
    color: black;
  }
  .catering .slick-center .card .item{
    transform: translateY(-60px);
    width: 80%;
    transition: transform 2s, width 2s;
  }
  .catering .slick-center h1{
    transform: translateY(-40px);
    font-size: 32px;
    font-weight: 800;
    transition: transform 2s, font-size 2s, font-weight 2s, color 2s;
    color: black;
  }
  .catering .slick-center .icon{
    transform: translateY(-20px);
    width: 20%;
    opacity: 1;
    transition: width 2s, transform 2s, opacity 2s;
  }

  .catering .slick-prev, .slick-next{
    margin: 0 -15px;
    z-index: 11;
    bottom: 0;
    left: 140px;
  }

  .catering .slick-prev:before, .slick-next:before{
    font-size: 40px;
  }

  .catering .details{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

.catering .details h2{
  display: flex;  
  align-items: center;
}
.catering .details img{
  width: 50px;
}

  
.slider_sect{
  display: flex;
}
.cate_ass{
  width: 150px;
  height: auto;
  display: none;
}


/*RESPONSIVE DESIGN FOR CATERING*/
@media screen and (max-width:860px) {
  .catering .slick-slider{
    width: 100% !important;
  }
  .catering .carousel-section{
    height: 700px !important;
  }
  .catering .slick-list{
    padding: 0 !important;
  } 
  .catering .card{
    width:200px;
    height: 300px;
  }
  .catering .slick-track{
    margin-top: 80px !important;
    margin-left: 100px;
  }
  .catering .slick-slide{
    width: 320px !important;
  }
}