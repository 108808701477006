
  /* Hero Section */
  .about-us .hero {
    text-align: center;
    color: black;
    text-align: center; 
    margin-top:80px;
  }
  
  .about-us .hero h1 {
    font-size: 48px;
    font-weight: bold;
    margin: 0;
    color: #EA1B25;
  }
  
  .about-us .hero p {
    font-size: 24px;
    margin-top: 10px;
  }
  
  /* Mission Section */
  .about-us .mission {
    text-align: center;
    flex-direction: row;
    margin-top:80px;
    height: 100%;
  }
  .about-us .content{
    display: flex;
    gap: 20px;
}
  .about-us .vijayamess{
    object-fit: cover;
    width: 40%;
    overflow: hidden;
    height: 450px ;
  }
  .about-us .vijayamess img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .about-us .vijayamessabt{
    background-color: #ebe1e1;
    height: auto;
    padding: 0 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width:860px){
    .about-us .content{
      flex-wrap: wrap;
    }
  
    .about-us .vijayamess{
      width: 100%;
      height: 300px;
    }

  }
  
  .about-us .mission h2 {
    font-size: 46px;
    color: #e38b06;
  }
  
  .about-us .mission p {
    font-size: 18px;
    max-width: 700px;
    margin: 20px auto;
    line-height: 1.6;
  }
  
  /* Chef Spotlight */
  .chef-spotlight {
    margin-top:80px;
    text-align: center;
  }
  
  .chef-spotlight h2 {
    font-size: 32px;
    color: #e38b06;
    margin-bottom: 20px;
  }
  
  .chef-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chef-container img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .chef-container p {
    max-width: 600px;
    font-size: 18px;
    text-align: center;
    line-height: 1.6;
  }

  /*Know | Knowmore */

  .know{
    display: flex;
    flex: 1 1;
    margin-top: 80px;
    padding: 0 10%;
    color: #333;
  }   
  .know img{
      width: 100%;
      height: 450px;
      object-fit: cover;
  }
  .knowmore{
    margin-left: 40px;
  }
  .knowmore h1{
    font-size: 46px;
    font-weight: 800;
  }
  .knowmore h3{
    color: #EA1B25;
    margin: 0;
  }
  .knowcontent{
    display: flex;
    width: 100%;
    gap: 40px;
  }
  @media (max-width:1500px){
    .know{
      padding: 0 5%;
    }
  }
  @media (max-width: 1000px){
    .know{
      padding: 0;
    }
  }
  @media (max-width:846px){
    .know{
      flex-wrap: wrap;
    }
    .knowmore{
      margin-left: 0;
      margin-top: 40px;
    }
    .knowmore h1{
      font-size: 36px;
    }
  }



  /* Testimonials */
  .testimonials {
    background-color: #f8f8f8;
    padding: 40px 20px;
    text-align: center;
  }
  
  .testimonials h2 {
    font-size: 32px;
    color: #e38b06;
    margin-bottom: 20px;
  }
  
  .testimonial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .testimonial {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    text-align: center;
  }
  
  .testimonial p {
    font-size: 18px;
    font-style: italic;
    color: #555;
  }
  
  .testimonial span {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    color: #e38b06;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 36px;
    }
  
    .hero p {
      font-size: 20px;
    }
  
    .mission h2, .chef-spotlight h2, .testimonials h2 {
      font-size: 28px;
    }
  
    .mission p, .chef-container p, .testimonial p {
      font-size: 16px;
    }
  
    .chef-container img {
      width: 150px;
      height: 150px;
    }
  }
  
