/*NAVBAR UI DESIGN*/
  .hotelfood{
    margin-top: 80px;
  }
  .foodheading{
    display: flex;
    justify-content: space-between;
  }
  .search-bar {
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
  }

  .hotelfood .navbar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;
    background-color: #F6951E;
    flex-direction: row;
    width: 100%;
    gap: 10px;
  }
  
  .hotelfood .navbar {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    position: relative;
    width: 85%;
  }
  
  .hotelfood .navbar-scroll {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px 0;
    width: calc(100% - 80px);
  }
  
  .hotelfood .navbar-scroll::-webkit-scrollbar {
    display: none;
  }
  
  .hotelfood .navbar-button {
    background: none;
    border: none;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    transition: all 0.3s ease;
    text-wrap: nowrap;
  }
  
  .hotelfood .navbar-button.active,
  .hotelfood .navbar-button:hover {
    background-color: #c7740f;
    transform: scale(1.1);
  }
  
  .scroll-arrow {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    padding: 10px;
    transition: transform 0.3s ease;
    width: 40px;
  }
  
  .scroll-arrow:hover {
    background-color: #c7740f;
  }
  
  .hotelfood .navbar-dropdown {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #F6951E;
    color: #fff;
    outline: none;
    margin-bottom: 10px;
  }
  .reset-button{
    display: block;
    margin-top: 0;
    width: auto;
    padding: 0;
  }
  .hotelfood .reset-container {
    background-color: #EA1B25;
    color: #fff;
    border: none;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    width: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 12px 20px;

  }
  .hotelfood .reset-container img{
    width: 34px;
    margin-right: 5px;
  }
  .reset-button:hover {
    background-color: #a65f0c;
  }
  
  /* Responsive Design */
  @media (max-width:1200px){
    .reset-button{
      display: none;
    }
  }
  @media (max-width: 768px) {
    /* .navbar {
      display: none;
    } */
  
    .navbar-dropdown {
      display: block;
    }
  }
  
  
  /*Product LIst CSS*/
  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 40px;
  }
  
  .product-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: left;
    flex: 1 1 250px;
    max-width: 350px;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.137);
  }
  .product-item:hover {
    transform: scale(1.05);
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    .product-item {
      flex: 1 1 200px; /* Smaller base size */
    }
  }
  
  @media (max-width: 480px) {
    .product-item {
      flex: 1 1 100%; /* Full width on extra-small screens */
      max-width: 100%;
    }
  }
  
  .product-item .item {
    height: 200px;  
    width: 100%;
  }
  
  .product-item .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .product-item:hover {
    transform: translateY(-5px);
  }
  
  .product-item h3 {
    margin: 5px 0;
    color: #333;
    text-transform: capitalize;
  }
  
  .product-item p {
    color: #777;
    font-size: 18px;
  }
  
  .product-item button {
    background-color: #28a745;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-top: auto;
  }
  
  .product-item button:hover {
    background-color: #218838;
  }
  
  /* Cart Styles */
  .cart {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin: 20px;
  }
  
  .cart h2 {
    margin-top: 0;
    color: #007bff;
  }
  
  .cart ul {
    list-style-type: none;
    padding: 0;
  }
  
  .cart li {
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .cart p {
    color: #777;
  }
  
  /* Pagination Styles */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-top: 60px;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination a {
    color: #F6951E;
    cursor: pointer;
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .pagination a:hover {
    background-color: #e9ecef;
  }
  
  .pagination .active a {
    background-color: #F6951E;
    color: white;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hotelfood .navbar {
      flex-direction: column;
      text-align: center;
    }
  
    .hotelfood .navbar input {
      width: 100%;
      margin-top: 10px;
    }
  }
  
.cart-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;
  }
  
  .cart-icon:hover {
    background-color: #0056b3;
  }
  