h2{
    margin: 0;
    padding: 0;
}
.section2{
    display: flex;
    
}
.penguin{
    display: flex;
    justify-content: center;
    margin-top: 80px;
    gap: 10px;
    margin-bottom: 80px;
}
@media (max-width: 860px) {
.penguin {
      flex-wrap: wrap;
    }}

.workers{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
}
.workers img{
    height: 300px;
}



/*OFFER SECTION CSS STYLING */

.offerSection{
    display: flex;
    gap: 25px;
    justify-content: space-between;
    margin-top: 80px;

    
}
.offers{
    padding: 40px;
    color: white;
    flex: 1 1 100%;
}
.offers button{
    border: none;
    padding: 10px 30px;
    margin: 10px 0;
    background-color: #F6951E;
}

@media screen and (max-width:860px){
  .offerSection{
    flex-wrap: wrap;
  }
  .offers{
    padding: 20px;
  }
}

/*REVIEW CSS STYLE */

  
  .reviews-section {
    padding: 40px 20px;
    text-align: center;
    color: #333;
  }
  
  .reviews-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .reviews-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: stretch;
  }
  
  .review-card {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 10px;

    padding: 20px;
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s ease;
    flex: 1 1 200px;
  }
  
  .review-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .review-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .review-name {
    font-size: 1.2em;
    margin: 10px 0 5px;
  }
  
  .review-text {
    font-size: 0.9em;
    color: #666;
    line-height: 1.5;
    margin: 10px 0 15px;
  }
  
  .review-stars {
    font-size: 1.2em;
    color: #FFC300;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .review-card {
      max-width: 100%;
    }
  
    .reviews-title {
      font-size: 2em;
    }
    .foodheading {
      display: block !important;
    }
  }
