
.contact-us {
    font-family: Arial, sans-serif;
    color: #333;
    margin: auto;
  }
  
  .contact-details{
    display: flex;
    flex: 1 1 100%;
    margin-top: 80px;
    gap: 40px;
  }

  .contact-info {
    background-color: #f3f3f3;
    padding: 20px 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 50%;
    
  }
  
  .contact-info h1 {
    font-size: 28px;
    margin-bottom: 10px;
    color: #e38b06;
  }
  
  .info-container {
    margin-top: 20px;
    width: 100%;
  }
  
  .info-item {
    width: 100%;
  }
  
  .info-item h3 {
    font-size: 20px;
    color: #555;
  }
  
  .info-item p {
    font-size: 18px;
    color: #333;
  }
  

  .contact-form {
    background-color: #fff;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 30px;
    width: 50%;
  }
  
  .contact-form h2 {
    font-size: 32px;
    color: #e38b06;
    margin-bottom: 20px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contact-form input, .contact-form textarea {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .contact-form button {
    text-align: center;
    font-size: 18px;
    color: #fff;
    background-color: #e38b06;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #c47405;
  }
  

  .map {
    text-align: center;
    margin-top: 80px;
  }
  
  .map h2 {
    font-size: 32px;
    color: #e38b06;
  }
  
  .map-container {
    margin: auto;
    overflow: hidden;
  }
  

  @media (max-width: 768px) {
    .info-container {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-info h1, .contact-form h2, .map h2 {
      font-size: 28px;
    }
  
    .contact-form input, .contact-form textarea, .contact-form button {
      width: 100%;
    }
  
    .contact-details{
      flex-wrap: wrap;
    }
    .contact-form{
      width: 100%;
    }
    .contact-info{
      width: 100%;
    }
  }

