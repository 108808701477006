body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (max-width:860px) {
  .container{
    margin: 0 5%;
  }  
}
.container{
  margin: 0 10%;
}
span{
  padding: 0;
  margin: 0;
}
h1{
  padding: 0;
  margin: 0;
}
.mainfont{
  font-size: 36px;
  font-weight: bold;
  color: rgb(32, 31, 31);
  margin-bottom: 20px;
}
.commonBanner{
  background:url("https://cinevijayammess.com/product/offers/com_ban.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  text-align: center;
  color: #F6951E;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 1000;
}