/* App.css */
  .runDiv{
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 10px 0;
    margin-top: 80px;
  }
  .runText{
    display: flex;
    animation: scroll 10s linear infinite;
    white-space: nowrap;
    font-size: 110px;
    font-weight: bold;
    font-family: 'Suez One', serif;
    color: transparent;
    -webkit-text-stroke: 0.3px white;
    font-weight: 400;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  /*Reponsive Design*/
  @media screen and (max-width:860px) {
    .runText{
      font-size: 80px;
      -webkit-text-stroke: 0.3px white;
    }  

    .ban-main{
      flex-wrap: wrap;
    }

    .ban-cont{
      margin-top: 0 !important;
      margin-left: 0 !important;
      text-align: center;
      align-items: center;
    }
    .ban-cont h1{
      font-size: 45px !important;
    }
    .ban-cont p{
      font-size: 16px !important;
    }

  } 




  .ban-main{
    display: flex;
    justify-content: space-between;
  }

  .ban-cont{
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 100px;
  }
  .ban-cont h1{
    font-size: 60px;
    font-family: 'Suez One', serif;
  }
  .ban-cont .feel{
    color: white;
  }
  .ban-cont .taste{
    color: #F6951E;
  }
  .ban-cont p{
    width: 60%;
    font-size: 20px;
    font-weight: 300;
    color: white;
    margin: 0;
  }
  button {
    background-color: #e43535;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-top: 15px;
    font-size: 16px;
    width: 180px;
  }
  
  /* Button Hover Effect */
  button:hover {
    background-color: #bd2323;
  }

  /*CAROUSEL SLIDER DESIGNS*/


  .slick-slider{
    width: 50%;
  }
  .slick-slide{
    width: 380px !important;
  }

  .carousel-section {
    position: relative;
    width: 100%;
    height: 800px;
    margin: 0 auto;
    overflow: hidden;
    background-color: rgba(27, 25, 25, 0.829);
    
  }
  
  /* Background behind the carousel */
  .carousel-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 1;
    z-index: -1;
    transition: background 0.5s ease;
  }
  
  /* Carousel card */
  .slick-track{
    height: 470px;
    margin-top: 100px;
  }
  .card {
    background-color: rgba(255, 255, 255, 0.438);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    width: 320px;
    height: 350px;
    flex-direction: column;

  }
.card .icon{
    opacity: 0;
    width: 0;
    transform: translateY(40px);
    width: 10%;
    margin-top: 5px;
}  
  .card .item {
    width: 65%;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
  }

  .card h1{
    font-size: 30px;
    margin-top: 5px;
    color: white;
    font-weight: 400;
    
  }
  /* Active card styling */
  .slick-center .card {
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    transition: background-color 2s;
  }
  .slick-center .card .item{
    transform: translateY(-60px);
    width: 80%;
    transition: transform 2s, width 2s;
  }
  .slick-center h1{
    transform: translateY(-40px);
    font-size: 32px;
    font-weight: 800;
    transition: transform 2s, font-size 2s, font-weight 2s, color 2s;
    color: black;
  }
  .slick-center .icon{
    transform: translateY(-20px);
    width: 20%;
    opacity: 1;
    transition: width 2s, transform 2s, opacity 2s;
  }

  .slick-prev, .slick-next{
    top: 88%;
    margin: 0 75px;
    z-index: 11;
  }

  .slick-prev:before, .slick-next:before{
    font-size: 40px;
  }

@media screen and (max-width:860px) {
  .slick-slider{
    width: 100% !important;
  }
  .carousel-section{
    height: 940px !important;
  }
  .slick-list{
    padding: 0 !important;
  } 
  .card{
    width:200px;
    height: 260px;
  }
  .slick-track{
    margin-top: 100px !important;
    margin-left: 450px;
  }
  .slick-slide{
    width: 220px !important;
  }
  
}