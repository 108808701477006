.cart-sidebar {
    position: fixed;
    right: -300px;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: #f8f9fa;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    transition: right 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .cart-sidebar.visible {
    right: 0;
  }
  
  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #007bff;
    color: white;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  
  .cart ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .cart li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .remove-btn {
    margin-left: 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .whatsapp-share-btn {
    background-color: #25D366;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    width: calc(100% - 20px);
    text-align: center;
  }
  
  .whatsapp-share-btn:hover {
    background-color: #1ebe57;
  }
  