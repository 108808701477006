.header {
  background-color: rgba(58, 51, 51, 0); 
  color: rgb(0, 0, 0);
  padding: 10px 0;
  width: 100%;
  position: fixed;
  z-index: 10;
  transition: background-color 0.3s ease; 
}

.header.sticky {
  background-color: white;
  color: black;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.navbar img {
  height: 50px;
}

@media screen and (max-width:860px) {
  .navbar img{
    height: 50px;
  }
}
.mobilmenu-close {
  color: #fff;
    font-size: 30px;
    position: absolute;
    top: 30px;
    right: 60px;
    padding: 0px 10px;
}
/* Navigation Menu */
.nav-list {
  list-style-type: none;
  display: flex;
  gap: 40px;
}

.nav-list li a {
  text-decoration: none;
  color: white;
}
.header.sticky .nav-list li a {
  color: black; /* Change link color to black when sticky */
}
.header.header.sticky .nav-list li .active{
  color: #EA1B25;
  font-weight: bold;
}

.nav-list li .active {
  color: #EA1B25;
  font-weight: bold;
}

/* Menus Button */
.menu-button {
  padding: 8px 15px;
  background-color: #ffffff41;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
}

.menu-button img {
  height: 20px;
}

/* Menubar container */
.menubar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

}

/* Responsive Styles */
@media screen and (max-width: 860px) {
  .nav-list {
      display: none; /* Hide nav list on small screens */
  }
  
  .menu-button {
      display: block;
  }
}

@media screen and (min-width: 860px) {
  .menu-button {
      display: none; /* Hide menu button on large screens */
  }
  .menubar{
    justify-content: space-between !important;
    gap: 0 !important;
  }
}

/* For Mobile Navigation - Overlay Menu */
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}

.mobile-menu.show {
  visibility: visible;
  opacity: 1;
}

.mobile-menu a {
  font-size: 24px;
  color: white;
  text-decoration: none;
}


.hamburger-icon {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  width: 70px;
}
.sticky .hamburger-icon {
  background: #e43535;
  margin: auto;
}

@media screen and (max-width:860px) {
  .hamburger-icon {
      display: block;
  }

  .nav-list {
      display: none;
  }
}


