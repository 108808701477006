.footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    text-align: center;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.social-media {
    margin:20px 0;
}

.social-media a {
    margin: 0 20px; 
}

.social-media img {
    width: 50px;
    height: auto; 
    transition: transform 0.3s;
}

.social-media img:hover {
    transform: scale(1.1);
}

.contact {
    margin-bottom: 20px;
}

.contact p {
    margin: 5px 0; 
}

.copyright {
    font-size: 14px;
    color: #aaa;
}

.copyright a {
    color: #fff;
}